import React from "react";
import './Emergency.css'

export default function Emergency() {
    return (
        <div className="cnt_emergency">
            <div className="subcnt_emergency">
                <a className="emergency-number" rel="nofollow" href="tel:+573144658752">314 465 8752</a>
                <p>|</p>
                <p className="emergency-text">¿Tienes una Emergencia?</p>
            </div>
        </div>
    )
}