import React, { useState } from "react";
import './Home.css'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import whatsapp from '../../Assets/svg/whatsapp.svg'
import washermachine from '../../Assets/svg/washermachine.svg'
import client from '../../Assets/svg/clients.svg'
import portfolio from '../../Assets/svg/portfolio.svg'
import warranty from '../../Assets/svg/warranty.svg'
import waveup from '../../Assets/svg/wavehome.svg'
import wavedown from '../../Assets/svg/wavehomedown.svg'
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

import payment from '../../Assets/images/payment.png'

import haceb from '../../Assets/images/haceb.png'
import mabe from '../../Assets/images/mabe.png'
import electrolux from '../../Assets/images/electrolux.png'
import samsungsp from '../../Assets/images/samsungsp.png'
import samsungft from '../../Assets/images/samsungft.png'
import lgft from '../../Assets/images/lgft.png'
import lgsp from '../../Assets/images/lgsp.png'
import whirlpool from '../../Assets/images/whirlpool.png'
import centrales from '../../Assets/images/centrales.png'
import challenger from '../../Assets/images/challenger.png'



export default function Home() {

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 5,  // Puedes ajustar según el tamaño de las imágenes y la separación
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: "linear",
        arrows: false,
        pauseOnHover: false,
        centerMode: true,   // Esto centrará el contenido y mostrará el espacio entre las imágenes
        centerPadding: '0px', // Ajustar espacio en los bordes si lo necesitas
    };

    return (
        <main className="cnt_home">
            <Helmet>
                <title>Mantenimiento de Lavadoras en Bogotá a Domicilio | Servicio Técnico Especializado</title>
                <meta name="description"
                    content="Servicio técnico especializado en mantenimiento y reparación de lavadoras LG, Samsung, Whirlpool y más marcas en Bogotá. ⚡ Atención inmediata a domicilio con garantía." />
                <meta name="keywords"
                    content="mantenimiento lavadoras bogota, reparacion lavadoras, servicio tecnico lavadoras, mantenimiento lg, mantenimiento samsung, reparacion whirlpool" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://mantenimientolavadorasariza.com" />

                <meta property="og:title" content="Mantenimiento de Lavadoras en Bogotá | Servicio Técnico Profesional" />
                <meta property="og:description" content="Servicio técnico especializado en mantenimiento y reparación de lavadoras. Atención a domicilio en todo Bogotá." />
                <meta property="og:image" content="https://res.cloudinary.com/dm9pjru06/image/upload/v1726877966/logo_bfq6ip.png" />
                <meta property="og:url" content="https://mantenimientolavadorasariza.com" />
                <meta property="og:type" content="website" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Mantenimiento de Lavadoras en Bogotá" />
                <meta name="twitter:description" content="Servicio técnico especializado en mantenimiento de lavadoras a domicilio." />
                <meta name="twitter:image" content="https://res.cloudinary.com/dm9pjru06/image/upload/v1726877966/logo_bfq6ip.png" />

                <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "LocalBusiness",
                            "name": "Mantenimiento de Lavadoras Ariza",
                            "image": "https://res.cloudinary.com/dm9pjru06/image/upload/v1726877966/logo_bfq6ip.png",
                            "description": "Servicio técnico especializado en mantenimiento y reparación de lavadoras en Bogotá",
                            "address": {
                                "@type": "PostalAddress",
                                "streetAddress": "Carrera 92 #129a 22",
                                "addressLocality": "Bogotá",
                                "addressRegion": "Cundinamarca",
                                "postalCode": "110111",
                                "addressCountry": "CO"
                            },
                            "geo": {
                                "@type": "GeoCoordinates",
                                "latitude": 4.7110,
                                "longitude": -74.0721
                            },
                            "url": "https://mantenimientolavadorasariza.com",
                            "telephone": "+573144658752",
                            "priceRange": "$",
                            "openingHoursSpecification": [
                                {
                                    "@type": "OpeningHoursSpecification",
                                    "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
                                    "opens": "08:00",
                                    "closes": "20:00"
                                },
                                {
                                    "@type": "OpeningHoursSpecification",
                                    "dayOfWeek": "Saturday",
                                    "opens": "09:00",
                                    "closes": "18:00"
                                }
                            ]
                        }
                    `}
                </script>
            </Helmet>
            <section className="cnt_welcome">
                <div className="cnt_welcome-title">
                    <h1 className="welcome-title">Servicio Técnico y Reparación de Lavadoras a Domicilio en Bogotá</h1>
                </div>
                <div className="cnt_welcome-text">
                    <p className="welcome-text">¡Estás en el lugar indicado para el mantenimiento de lavadoras! Ofrecemos servicio a domicilio para LG, Samsung, Kalley, Whirlpool, Centrales, Mabe, Electrolux, Haceb, y más. Contáctanos para más información y asegura el óptimo funcionamiento de tu lavadora.</p>
                </div>
                <div className="cnt_button-contact">
                    <div className="button_contact">
                        <img
                            src={whatsapp}
                            alt="Contactar por WhatsApp para servicio técnico"
                            className="svg"
                            width="32"
                            height="32"
                        />
                        <a
                            rel="noopener noreferrer"
                            href="https://wa.link/9wu34j"
                            className="decoration"
                            aria-label="Agendar servicio de mantenimiento por WhatsApp"
                        >
                            Agenda Aquí Tu Mantenimiento
                        </a>
                    </div>
                </div>
            </section>
            <section id="about" className="cnt_info">
                <div className="cnt_info-text">
                    <h2 className="info-title">¡Mantenimiento de Lavadoras en tu Casa Rápido!</h2>
                    <p className="info-text-sec">Ofrecemos servicio técnico especializado y mantenimiento de lavadoras de todo tipo, incluyendo lavadoras de carga frontal (de todas las marcas disponibles en el mercado actual), lavadoras de carga superior (también de todas las marcas) y centros de lavado.</p>
                </div>
                <article className="cnt_numbers grid">
                    <div className="info-service-grid">
                        <p className="numbers-grid">1</p>
                        <h3 className="info-text text-grid">Puedes programar el servicio</h3>
                    </div>
                    <div className="info-service-grid">
                        <p className="numbers-grid">2</p>
                        <h3 className="info-text text-grid">Revisamos la lavadora completa</h3>
                    </div>
                    <div className="info-service-grid">
                        <p className="numbers-grid">3</p>
                        <h3 className="info-text text-grid">Generamos Cotización para el cliente</h3>
                    </div>
                    <div className="info-service-grid">
                        <p className="numbers-grid">4</p>
                        <h3 className="info-text text-grid">Reparamos tu lavadora rápido y fácil</h3>
                    </div>
                </article>
            </section>
            <div className="cnt_button-link">
                <Link to="/preguntas-frecuentes" className="button-link">Ver preguntas frecuentes de nuestro servicio</Link>
            </div>
            <div id="services" className="cnt_wm">
                <h2 className="info-title">¡SERVICIO TECNICO PARA LAVADORAS DE TODAS LAS MARCAS!</h2>
                <p className="info-text-sec"><strong>No importa</strong> si tu lavadora no es del año. Ofrecemos servicio tecnico y mantenimiento para cualquier referencia de lavadoras</p>
                <div className="cnt_wm-cards">
                    <Link to="/lg" className="wm-item" title="Mantenimiento especializado para lavadoras LG">
                        <img src={lgsp}
                            alt="LG" className="wm-image" loading="lazy" />
                        <h3 className="wm-name">Mantenimiento de lavadoras LG de carga superior</h3>
                    </Link>
                    <Link to="/samsung" className="wm-item" title="Mantenimiento especializado para lavadoras Samsung">
                        <img src={samsungsp}
                            alt="Samsung" className="wm-image" loading="lazy" />
                        <h3 className="wm-name">Mantenimiento de lavadoras Samsung de carga superior</h3>
                    </Link>
                    <Link to="/lg" className="wm-item" title="Mantenimiento especializado para lavadoras LG">
                        <img src={lgft}
                            alt="LG" className="wm-image" loading="lazy" />
                        <h3 className="wm-name">Mantenimiento de lavadoras LG de carga frontal</h3>
                    </Link>
                    <Link to="/samsung" className="wm-item" title="Mantenimiento especializado para lavadoras Samsung">
                        <img src={samsungft}
                            alt="Samsung" className="wm-image" loading="lazy" />
                        <h3 className="wm-name">Mantenimiento de lavadoras Samsung de carga frontal</h3>
                    </Link>
                    <Link to="/whirlpool" className="wm-item" title="Mantenimiento especializado para lavadoras Whirlpool">
                        <img src={whirlpool}
                            alt="Whirlpool" className="wm-image" loading="lazy" />
                        <h3 className="wm-name">Mantenimiento de lavadoras Whirlpool de carga superior y frontal</h3>
                    </Link>
                    <Link to="/centrales" className="wm-item" title="Mantenimiento especializado para lavadoras Centrales">
                        <img src={centrales}
                            alt="Centrales" className="wm-image" loading="lazy" />
                        <h3 className="wm-name">Mantenimiento de lavadoras Centrales de carga superior y frontal</h3>
                    </Link>
                    <Link to="/electrolux" className="wm-item" title="Mantenimiento especializado para lavadoras Electrolux">
                        <img src={electrolux}
                            alt="Electrolux" className="wm-image" loading="lazy" />
                        <h3 className="wm-name">Mantenimiento de lavadoras Electrolux de carga superior y frontal</h3>
                    </Link>
                    <Link to="/mabe" className="wm-item" title="Mantenimiento especializado para lavadoras Mabe">
                        <img src={mabe}
                            alt="Mabe" className="wm-image" loading="lazy" />
                        <h3 className="wm-name">Mantenimiento de lavadoras Mabe de carga superior y frontal</h3>
                    </Link>
                    <Link to="/challenger" className="wm-item" title="Mantenimiento especializado para lavadoras Challenger">
                        <img src={challenger}
                            alt="Challenger" className="wm-image" loading="lazy" />
                        <h3 className="wm-name">Mantenimiento de lavadoras Challenger de carga superior y frontal</h3>
                    </Link>
                    <Link to="/haceb" className="wm-item" title="Mantenimiento especializado para lavadoras Haceb">
                        <img src={haceb}
                            alt="Haceb" className="wm-image" loading="lazy" />
                        <h3 className="wm-name">Mantenimiento de lavadoras Haceb de carga superior y frontal</h3>
                    </Link>
                </div>
            </div>
            <img src={waveup} alt="wave" className="wave" role="presentation" aria-hidden="true"/>
            <div className="cnt_payments">
                <h2 className="info-title-payments">PAGA FACIL Y HAZLE MANTENIMIENTO A TU LAVADORA</h2>
                <p className="info-text-payments">Disponemos de una amplia variedad de métodos de pago para adaptarnos a tus necesidades. Entre ellos se incluyen la opción de realizar transferencias bancarias, el uso de billeteras digitales como alternativas modernas y convenientes, y también la posibilidad de pagar en efectivo. En algunas situaciones, ofrecemos además la opción de procesar pagos con tarjetas de crédito y débito</p>
                <img src={payment}
                    alt="Métodos de pago aceptados" className="payments" loading="lazy" />
            </div>
            <img src={wavedown} alt="wave" className="wavedown" role="presentation" aria-hidden="true" />
            <section id="about" className="cnt_info_numbers">
                <h2 className="info-title">Somos la mejor decision para el Mantenimiento de Lavadoras en Bogotá</h2>
                <p className="info-text-sec">Además de la garantía que brindamos por nuestro trabajo y la amplia zona de trabajo que manejamos, lo demostramos con <strong>Numeros Reales</strong>.</p>
                <article className="cnt_numbers">
                    <div className="info-service">
                        <img src={washermachine} alt="lavadora-imagen" className="svg" />
                        <p className="numbers">5000+</p>
                        <p className="info-text">Lavadoras Arregladas</p>
                    </div>
                    <div className="info-service">
                        <img src={client} alt="cliente-imagen" className="svg" />
                        <p className="numbers">4000+</p>
                        <p className="info-text">Clientes</p>
                    </div>
                    <div className="info-service">
                        <img src={portfolio} alt="portafolio-mantenimiento" className="svg" />
                        <p className="numbers">15+</p>
                        <p className="info-text">Años en el Mercado</p>
                    </div>
                    <div className="info-service">
                        <img src={warranty} alt="garantia-mantenimiento" className="svg" />
                        <p className="numbers">2000+</p>
                        <p className="info-text">Nos recomiendan</p>
                    </div>
                </article>
            </section>
            <div className="cnt_button-link">
                <Link to="/sobre-mantenimiento-de-lavadoras-ariza" className="button-link" title="Más información sobre nosotros">Saber más sobre nosotros</Link>
            </div>
            <div className="carousel">
                <Slider {...settings} className="cnt_slider">
                    <div className="carousel-slide">
                        <img
                            src="https://centrosdeservicio.com/wp-content/uploads/2023/12/marca-servicio-tecnico-haceb-soporte-reparacion-arreglo-1.png"
                            alt="LG Logo"
                            loading="lazy"
                        />
                    </div>
                    <div className="carousel-slide">
                        <img
                            src="https://centrosdeservicio.com/wp-content/uploads/2023/12/marca-servicio-tecnico-whirlpool-soporte-reparacion-arreglo-1.png"
                            alt="Whirlpool Logo"
                            loading="lazy"
                        />
                    </div>
                    <div className="carousel-slide">
                        <img
                            src="https://centrosdeservicio.com/wp-content/uploads/2023/12/marca-servicio-tecnico-samsung-soporte-reparacion-arreglo-1.png"
                            alt="Samsung Logo"
                            loading="lazy"
                        />
                    </div>
                    <div className="carousel-slide">
                        <img
                            src="https://centrosdeservicio.com/wp-content/uploads/2023/12/marca-servicio-tecnico-mabe-soporte-reparacion-arreglo-1.png"
                            alt="Haceb Logo"
                            loading="lazy"
                        />
                    </div>
                    <div className="carousel-slide">
                        <img
                            src="https://centrosdeservicio.com/wp-content/uploads/2023/12/marca-servicio-tecnico-bosch-soporte-reparacion-arreglo-1.png"
                            alt="Electrolux Logo"
                            loading="lazy"
                        />
                    </div>
                    <div className="carousel-slide">
                        <img
                            src="https://centrosdeservicio.com/wp-content/uploads/2023/12/marca-servicio-tecnico-electrolux-soporte-reparacion-arreglo-1.png"
                            alt="Centrales Logo"
                            loading="lazy"
                        />
                    </div>
                    <div className="carousel-slide">
                        <img
                            src="https://centrosdeservicio.com/wp-content/uploads/2023/12/marca-servicio-tecnico-kalley-soporte-reparacion-arreglo-1.png"
                            alt="Mabe Logo"
                            loading="lazy"
                        />
                    </div>
                </Slider>
            </div>
            <div className="widthcnt">
                <div className="button_contact">
                    <img src={whatsapp} alt="whatsapp-logo" className="svg" loading="lazy" />
                    <a rel="nofollow" href="https://wa.link/9wu34j" className="decoration">Agenda Aquí</a>
                </div>
            </div>
        </main>
    )
}