import React from "react";
import './Services.css'
import wavedown from '../../Assets/svg/wavehomedown.svg'
import { Helmet } from 'react-helmet-async';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function Services() {

    const settings = {
        dots: true,  // Muestra los puntos de navegación
        infinite: true,  // Habilita el ciclo infinito de las imágenes
        speed: 500,  // Velocidad de la transición en milisegundos
        slidesToShow: 1,  // Cantidad de imágenes visibles a la vez
        slidesToScroll: 1,  // Cantidad de imágenes que se mueven por scroll
        autoplay: true,  // Autoplay de las imágenes
        autoplaySpeed: 3000,  // Tiempo entre imágenes en autoplay
        arrows: true,  // Mostrar flechas de navegación
    };

    // Array de imágenes
    const images = [
        'https://res.cloudinary.com/dm9pjru06/image/upload/v1722968503/lavadoras/after_pz0enp.jpg',
        'https://res.cloudinary.com/dm9pjru06/image/upload/v1722968656/lavadoras/before_uibu0c.jpg',
    ];

    return (
        <div className="cnt_services">
            <Helmet>
                <title>Servicios de Limpieza, Mantenimiento y Servicio Tecnico de Lavadoras en Bogotá</title>
                <meta name="description"
                    content="En Mantenimientos Ariza S.A brindamos servicio tecnico especializado y mantenimiento a profundidad de Lavadoras en el norte de Bogotá a domicilio. Llamanos y agenda tu Mantenimiento" />
                <meta name="keywords"
                    content="mantenimiento lavadoras bogota, reparacion lavadoras, servicio tecnico lavadoras, mantenimiento lg, mantenimiento samsung, reparacion whirlpool" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://mantenimientolavadorasariza.com" />

            </Helmet>
            <div className="cnt_welcome-services">
                <h1 className="services-title">Servicios de Mantenimiento de Lavadoras</h1>
                <p className="services-text">¿Buscas especialistas en Mantenimiento de Lavadoras? Te podemos ayudar con eso. <br /> Arreglamos cualquier lavadora en Bogotá y ¡Con servicio a domicilio!</p>
            </div>
            <img src={wavedown} alt="wave" className="wave service" />
            <div className="cnt_cards-service">
                <div className="card-services">
                    <h2 className="card-services-title">Limpieza de Lavadora Internamente</h2>
                    <p>El mantenimiento de la Lavadora consta de <strong>Limpieza de agitador, atrapa motas, extracción de las tinas y limpieza de las mismas </strong>(pulir, quitar exceso de mugre, oxido, etc),<strong>  se destapan bombas de entrada y salida de agua. Ajuste de correa. Limpieza de manguera de presostato, ajuste de reloj, paneles, etc.</strong></p>                </div>
                <div className="card-services">
                    <h2 className="card-services-title">Mantenimiento de los Sistemas Internos de la Lavadora</h2>
                    <p>Nuestros técnicos revisan las partes eléctricas con multímetro; como capacitor, presostato, tarjeta principal, cableado, bombas de entrada y salida; y partes mecánicas como motor y transmisión. Se brinda un reporte de los problemas encontrados en la Lavadora.</p>
                    <div className="images-services">
                        <img
                            src="https://http2.mlstatic.com/D_NQ_NP_993451-MCO49942339092_052022-O.webp"
                            alt="lavadora"
                            className="images-comp margin"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div className="card-services">
                    <h2 className="card-services-title">Reparación interna y externa de cualquier Lavadora</h2>
                    <p>Una vez identificado el problema, procedemos a brindar el reporte y el cliente aceptado el presupuesto. Se le brinda el repuesto original. Brindandole garantia de manupulación, instalación y resultados.</p>
                </div>
            </div>
        </div>
    )
}