import React from "react";
import './Buttons.css'

import phone from '../../Assets/images/telefono-logo.png'
import whatsapp from '../../Assets/images/whatsapp-logo.png'

export default function Buttons() {
    return (
        <div>
            <a rel="nofollow" href="tel:+573144658752"><img src={phone} alt="whatsapp"
                class="telefono-logo" /></a>
            <a rel="nofollow" href="https://wa.link/9wu34j"><img src={whatsapp} alt="whatsapp"
                class="whatsapp-logo" /></a>
        </div>
    )
}