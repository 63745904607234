import React from "react";
import './Footer.css'
import logo from '../../Assets/images/logo.png';

export default function Footer() {
    return (
        <footer className="cnt_contact">
            <div className="contact">
                <div className="footer-logo-image">
                    <img loading="lazy"
                        src={logo}
                        alt="logo" className="logo-footer" />
                </div>
                <p className="address">Carrera 92 #129a - 22, Oficina 301 <br /> Nos reservamos los derechos de autoría y nombre
                </p>
            </div>
            <div className="cnt_social-logo">
                <div className="social-phone">
                    <h2 className="about-p">Contáctanos para agendar el Mantenimiento</h2>
                        <p className="about-p"><strong class="color2">Tel - </strong> 314 465 8752</p>
                </div>
                <div>
                    <img src="https://centrosdeservicio.com/wp-content/uploads/2023/11/icono-footer-garantia-de-reparacion-electrodomesticos-centros-de-servicio-1.png"
                        alt="verification-google" className="verification" loading="lazy" />
                </div>
                <div className="cnt_address">
                    <p className="contact-style">Desarrollado por <a href="https://portfolio-virid-mu-25.vercel.app/"
                        className="name">Fabian Tinjaca</a></p>
                </div>
            </div>
        </footer>
    )
}