import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import Home from "../Components/Home/Home";
import Navbar from "../Components/Nav/Navbar";
import Buttons from "../Components/Buttons/Buttons";
import Footer from "../Components/Footer/Footer";
import About from "../Components/About/About";
import Services from "../Components/Services/Services";
import Emergency from "../Components/Emergency/Emergency";
import Questions from "../Components/questions/Questions";
import { HelmetProvider } from 'react-helmet-async';
import Lg from "../Components/EachWasherMachine/Lg";
import Samsung from "../Components/EachWasherMachine/Samsung";
import Whirlpool from "../Components/EachWasherMachine/Whirlpool";
import Centrales from "../Components/EachWasherMachine/Centrales";
import Electrolux from "../Components/EachWasherMachine/Electrolux";
import Mabe from "../Components/EachWasherMachine/Mabe";
import Challenger from "../Components/EachWasherMachine/Challenger";
import Haceb from "../Components/EachWasherMachine/Haceb";
import ScrollToTop from "./ScrollToTop";

function App() {

  return (
    <BrowserRouter>
      <HelmetProvider>
        <ScrollToTop />
        <Emergency />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sobre-mantenimiento-de-lavadoras-ariza" element={<About />} />
          <Route path="/servicios-de-mantenimiento-lavadoras" element={<Services />} />
          <Route path="/preguntas-frecuentes" element={<Questions />} />
          <Route path="/whirlpool" element={<Whirlpool />} />
          <Route path="/lg" element={<Lg />} />
          <Route path="/samsung" element={<Samsung />} />
          <Route path="/centrales" element={<Centrales />} />
          <Route path="/electrolux" element={<Electrolux />} />
          <Route path="/mabe" element={<Mabe />} />
          <Route path="/haceb" element={<Haceb />} />
          <Route path="/challenger" element={<Challenger />} />
        </Routes>
        <Buttons />
        <Footer />
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default App;