import React, { useState } from "react";
import whatsapp from '../../Assets/svg/whatsapp.svg'
import { Helmet } from 'react-helmet-async';

export default function Questions() {

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div>
            <Helmet>
                <title>Preguntas Frecuentes de Servicios de Mantenimiento de Lavadoras</title>
                <meta name="description"
                    content="En Mantenimientos Ariza S.A brindamos servicio tecnico especializado y mantenimiento a profundidad de Lavadoras en el norte de Bogotá a domicilio. Llamanos y agenda tu Mantenimiento" />
                <meta name="keywords"
                    content="mantenimiento lavadoras bogota, reparacion lavadoras, servicio tecnico lavadoras, mantenimiento lg, mantenimiento samsung, reparacion whirlpool" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://mantenimientolavadorasariza.com" />

            </Helmet>
            <div className="accordion">
                <h1 class="info-title">Preguntas frecuentes</h1>
                <p className="itext-title">Encontrarás las preguntas que más nos realizan los clientes al momento de realizar mantenimientos. <br /> <strong>Selecciona la pregunta de tu interes</strong></p>
                <div className="accordion-item">
                    <button className="accordion-header" onClick={() => toggleAccordion(0)}>
                        <h2 className="title-accordion">1. ¿En qué consiste el mantenimiento de mi lavadora?</h2>
                    </button>
                    <div className={`accordion-content ${activeIndex === 0 ? 'active' : ''}`}>
                        <p>El mantenimiento de la lavadora consta de limpieza de agitador, atrapa motas, extracción de tinas y limpieza, se destapan bombas de entrada
                            y salida de agua. Ajuste de correa. Limpieza de manguera de presostato. Se recomienda que la
                            limpieza sea anual.</p>
                        <div className="cnt_images-comp">
                            <div>
                                <p className="images-title-wm">Antes</p>
                                <img
                                    src="https://res.cloudinary.com/dm9pjru06/image/upload/v1722968656/lavadoras/before_uibu0c.jpg"
                                    alt="lavadora"
                                    className="images-comp"
                                    loading="lazy"
                                />
                                <img
                                    src="https://res.cloudinary.com/dm9pjru06/image/upload/v1722968671/lavadoras/before2_lc7zs8.jpg"
                                    alt="lavadora"
                                    className="images-comp"
                                    loading="lazy"
                                />
                                <img
                                    src="https://res.cloudinary.com/dm9pjru06/image/upload/v1722968593/lavadoras/after3_crrt3h.jpg"
                                    alt="lavadora"
                                    className="images-comp"
                                    loading="lazy"
                                />
                            </div>
                            <div>
                                <p className="images-title-wm">Después</p>
                                <img
                                    src="https://res.cloudinary.com/dm9pjru06/image/upload/v1722968503/lavadoras/after_pz0enp.jpg"
                                    alt="lavadora"
                                    className="images-comp"
                                    loading="lazy"
                                />
                                <img
                                    src="https://res.cloudinary.com/dm9pjru06/image/upload/v1722968548/lavadoras/after2_mcaptd.jpg"
                                    alt="lavadora"
                                    className="images-comp"
                                    loading="lazy"
                                />
                                <img
                                    src="https://res.cloudinary.com/dm9pjru06/image/upload/v1722968700/lavadoras/before3_btyyuv.jpg"
                                    alt="lavadora"
                                    className="images-comp"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div className="widthcnt">
                            <div className="button_contact">
                                <img src={whatsapp} alt="whatsapp-logo" className="svg" />
                                <a rel="nofollow" href="https://wa.link/9wu34j" className="decoration">
                                    Agenda Aquí
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <button className="accordion-header" onClick={() => toggleAccordion(1)}>
                        <h2 className="title-accordion">2. ¿En qué consiste la revisión o diagnóstico de la lavadora?</h2>
                    </button>
                    <div className={`accordion-content ${activeIndex === 1 ? 'active' : ''}`}>
                        <p>Nuestros técnicos revisan las partes eléctricas de la Lavadora con multímetro; como capacitor, presostato,
                            tarjeta principal, cableado, bombas de entrada y salida; y partes mecánicas como motor y
                            transmisión. Y se brinda un resumen de los problemas encontrados en su lavadora.</p>
                        <div className="widthcnt">
                            <div className="button_contact">
                                <img src={whatsapp} alt="whatsapp-logo" className="svg" />
                                <a rel="nofollow" href="https://wa.link/9wu34j" className="decoration">Agenda Aquí</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <button className="accordion-header" onClick={() => toggleAccordion(2)}>
                        <h2 className="title-accordion">3. ¿Hacemos servicio a domicilio para revisar mi lavadora?</h2>
                    </button>
                    <div className={`accordion-content ${activeIndex === 2 ? 'active' : ''}`}>
                        <p>Trabajamos nuestros servicios de Mantenimiento de Lavadoras a domicilio en Bogotá D.C., Cota, Chía, Funza, Madrid, Mosquera y el
                            municipio de La Calera.</p>
                    </div>
                </div>

                <h2 class="info-title">Fallas Comunes y Posibles Arreglos en Casa para tu lavadora</h2>

                <div className="accordion-item">
                    <button className="accordion-header" onClick={() => toggleAccordion(3)}>
                        <h2 className="title-accordion">1. La lavadora no llena de agua</h2>
                    </button>
                    <div className={`accordion-content ${activeIndex === 3 ? 'active' : ''}`}>
                        <p>Compruebe si la manguera de la lavadora está obstruida. Luego verifique si las mangueras de agua
                            están correctamente conectadas y por último revise que los grifos de agua estén suministrando
                            agua.</p>
                    </div>
                </div>

                <div className="accordion-item">
                    <button className="accordion-header" onClick={() => toggleAccordion(4)}>
                        <h2 className="title-accordion">2. La lavadora no enciende</h2>
                    </button>
                    <div className={`accordion-content ${activeIndex === 4 ? 'active' : ''}`}>
                        <p>Verifique si está enchufada y si hay flujo de corriente en la toma de corriente de su hogar.
                            Asegúrese de que el interruptor de encendido esté activado. Reemplace el fusible o reinicie el
                            interruptor si es necesario.</p>
                    </div>
                </div>

                <div className="accordion-item">
                    <button className="accordion-header" onClick={() => toggleAccordion(5)}>
                        <h2 className="title-accordion">3. La lavadora no drena agua</h2>
                    </button>
                    <div className={`accordion-content ${activeIndex === 5 ? 'active' : ''}`}>
                        <p>Verifique si hay obstrucciones en la bomba de la parte de atrás de la Lavadora. Revise que la manguera de salida
                            no esté doblada.</p>
                    </div>
                </div>

                <div className="accordion-item">
                    <button className="accordion-header" onClick={() => toggleAccordion(6)}>
                        <h2 className="title-accordion">4. La lavadora no centrifuga adecuadamente</h2>
                    </button>
                    <div className={`accordion-content ${activeIndex === 6 ? 'active' : ''}`}>
                        <p>Asegúrese de que la lavadora esté en buena posición para evitar que se mueva o sacuda. Levante la
                            lavadora y compruebe que la polea esté bien.</p>
                    </div>
                </div>

                <div className="accordion-item">
                    <button className="accordion-header" onClick={() => toggleAccordion(7)}>
                        <h2 className="title-accordion">5. La lavadora no llena de agua caliente</h2>
                    </button>
                    <div className={`accordion-content ${activeIndex === 7 ? 'active' : ''}`}>
                        <p>Verifique si el flujo de agua caliente está encendido. Limpie el filtro de agua para evitar
                            tapones de suciedad.</p>
                    </div>
                </div>

                <div className="accordion-item">
                    <button className="accordion-header" onClick={() => toggleAccordion(8)}>
                        <h2 className="title-accordion">6. La lavadora hace ruidos fuertes</h2>
                    </button>
                    <div className={`accordion-content ${activeIndex === 8 ? 'active' : ''}`}>
                        <p>Revise si hay objetos atrapados en el tambor o agitador de su lavadora. Asegúrese de que estén
                            correctamente puestas y niveladas las patas de la lavadora.</p>
                    </div>
                </div>

                <div className="accordion-item">
                    <button className="accordion-header" onClick={() => toggleAccordion(9)}>
                        <h2 className="title-accordion">7. La lavadora no bota el agua completamente</h2>
                    </button>
                    <div className={`accordion-content ${activeIndex === 9 ? 'active' : ''}`}>
                        <p>Revise si hay obstrucciones en la bomba de salida. Revise que la manguera de salida esté elevada
                            correctamente.</p>
                    </div>
                </div>

                <div className="accordion-item">
                    <button className="accordion-header" onClick={() => toggleAccordion(10)}>
                        <h2 className="title-accordion">8. La lavadora no lava correctamente</h2>
                    </button>
                    <div className={`accordion-content ${activeIndex === 10 ? 'active' : ''}`}>
                        <p>Utilice una cantidad adecuada de detergente y ajuste el ciclo de lavado según el tipo de ropa.
                            Verifique si el agitador está funcionando correctamente.</p>
                    </div>
                </div>

                <div className="accordion-item">
                    <button className="accordion-header" onClick={() => toggleAccordion(11)}>
                        <h2 className="title-accordion">9. Consumo excesivo de agua o energía</h2>
                    </button>
                    <div className={`accordion-content ${activeIndex === 11 ? 'active' : ''}`}>
                        <p>Utilice protectores de voltaje directamente en la toma para evitar daños por picos de luz.
                            Verifique que no tenga fugas de agua en la válvula de entrada.</p>
                    </div>
                </div>

                <div className="accordion-item">
                    <button className="accordion-header" onClick={() => toggleAccordion(12)}>
                        <h2 className="title-accordion">10. Malos olores en la lavadora</h2>
                    </button>
                    <div className={`accordion-content ${activeIndex === 12 ? 'active' : ''}`}>
                        <p>Puede usar limpiadores como vinagre para eliminar cualquier generador de malos olores. Si el olor
                            persiste, no dude en contactarnos.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}