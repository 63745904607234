import React from "react";
import './estiloswm.css'
import whatsapp from '../../Assets/svg/whatsapp.svg'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import mantenimiento1 from '../../Assets/images/mantenimiento1.jpg'
import mantenimiento2 from '../../Assets/images/mantenimiento2.jpg'
import mantenimiento3 from '../../Assets/images/mantenimiento3.jpg'
import mantenimiento4 from '../../Assets/images/mantenimiento4.jpg'

import { Helmet } from 'react-helmet-async'


export default function Lg() {

    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 400,
    }

    return (
        <div className="cnt_ewm">
            <Helmet>
                <title>Mantenimiento a Lavadoras LG en Bogotá</title>
                <meta name="description"
                    content="En Mantenimientos Ariza S.A brindamos servicio tecnico especializado y mantenimiento a profundidad de Lavadoras LG en el norte de Bogotá a domicilio. Llamanos y agenda tu Mantenimiento" />
                <meta name="keywords"
                    content="mantenimiento lavadoras bogota, reparacion lavadoras, servicio tecnico lavadoras, mantenimiento lg, mantenimiento samsung, reparacion whirlpool" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://mantenimientolavadorasariza.com" />

            </Helmet>
            <div className="cnt_welcome-e background-lg">
                <h1 className="title-wm-component">Mantenimiento a Lavadoras Lg en Bogotá</h1>
            </div>
            <div className="cnt_subtitle-wm">
                <h2 className="subtitle-wm-component">Ofrecemos servicios de Mantenimiento y reparaciones a lavadoras LG de carga superior y frontal</h2>
                <p className="description-subtitle-wm-component">Si buscas un servicio confiable de mantenimiento de lavadoras LG a domicilio en Bogotá, ¡estás en el lugar correcto! Nos especializamos en brindar soluciones rápidas y efectivas para garantizar que tu lavadora LG funcione como nueva. Nuestros clientes nos prefieren no solo por la calidad del servicio, sino también por nuestros precios económicos, ideales para cualquier presupuesto. Ofrecemos atención personalizada y técnicos especializados que se desplazan hasta tu hogar, evitando inconvenientes y asegurando una reparación oportuna y eficiente. ¡Confía en nosotros para mantener tu lavadora LG en perfecto estado!</p>
            </div>
            <Slider {...settings}>
                <div className="carousel-slide-wm">
                    <img
                        src={mantenimiento1}
                        alt="LG Logo"
                        loading="lazy"
                    />
                </div>
                <div className="carousel-slide-wm">
                    <img
                        src={mantenimiento2}
                        alt="Whirlpool Logo"
                        loading="lazy"
                    />
                </div>
                <div className="carousel-slide-wm">
                    <img
                        src={mantenimiento3}
                        alt="Samsung Logo"
                        loading="lazy"
                    />
                </div>
                <div className="carousel-slide-wm">
                    <img
                        src={mantenimiento4}
                        alt="Samsung Logo"
                        loading="lazy"
                    />
                </div>
            </Slider>
            <h2 className="subtitle-wm-component">Ofrecemos beneficios excepcionales</h2>
            <div className="cnt_wmcard">
                <div className="wm-item-component">
                    <h3 className="item-component-text">REPUESTOS ORIGINALES</h3>
                </div>
                <div className="wm-item-component">
                    <h3 className="item-component-text">CALIDAD Y PRECIOS ECONOMICOS</h3>
                </div>
                <div className="wm-item-component">
                    <h3 className="item-component-text">ATENCION PERSONALIZADA</h3>
                </div>
                <div className="wm-item-component">
                    <h3 className="item-component-text">REPARACIONES RAPIDAS Y DISPONIBILIDAD</h3>
                </div>
            </div>
            <div className="cnt_contact-wm">
                <h2 className="subtitle-wm-component">Puedes agendar tu Mantenimiento a través de nuestro servicio en línea</h2>
                <div class="button_contact">
                    <img src={whatsapp} alt="whatsapp-logo" class="svg" />
                    <a rel="nofollow" href="https://wa.link/9wu34j" class="decoration">Agenda Aquí Tu Mantenimiento</a>
                </div>
            </div>
        </div>
    )
}