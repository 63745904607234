import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './Containers/App';
import '@fontsource/montserrat/200.css'; // Peso normal
import '@fontsource/montserrat/300.css'; // Peso normal
import '@fontsource/montserrat/400.css'; // Peso normal
import '@fontsource/montserrat/700.css'; // Peso negrita
import '@fontsource/montserrat/800.css'; // Peso normal
import '@fontsource/montserrat/900.css'; // Peso normal


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
